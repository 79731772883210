//export const registerServiceWorker = () => true

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )

  if (answer === true) {
    window.location.reload()
  }
}

import './src/styles/global.css'
import './src/styles/slider.css'
